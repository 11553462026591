<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-container fluid class="h-100 pa-0">
    <v-btn fab depressed small top right absolute class="mt-6 close-space white" @click="() => $router.push('/')">
      <v-icon class="primary--text">$close</v-icon>
    </v-btn>
    <core-stepper v-model="step" class="h-100">
      <space-form-step
        step="name"
        title="Création de mon espace"
        subtitle="Créer un nouvel espace de travail"
      >
        <space-form-name :name.sync="form.space.name"
                         :slug.sync="form.space.slug"
                         @next="step='establishment'"
        />
      </space-form-step>

      <space-form-step
        step="establishment"
        title="Relier mon espace de travail"
        subtitle="Relier mon espace à un établissement ou une CPTS"
      >
        <space-form-establishment
          v-model="form.establishment"
          @prev="step='name'"
          @next="step='establishment_search'"
          @skip="submit"
        />
      </space-form-step>
      <space-form-step
        step="establishment_search"
        title="Relier mon espace de travail"
        subtitle="Relier mon espace à un établissement ou une CPTS"
      >
        <space-form-establishment-select
          v-model="form.space.establishment"
          @prev="step='establishment'"
          @skip="submit"
          @next="submit"
          @create="step='establishment_create'"
        />
      </space-form-step>
      <space-form-step
        step="establishment_create"
        title="Relier mon espace de travail"
        subtitle="Créer un établissement pour le relier à mon espace"
      >
        <space-form-establishment-create v-model="form.establishment"
                                         @prev="step='establishment_search'"
                                         @next="submit"
        />
      </space-form-step>
      <space-form-step
        step="submitting"
        title="Création de mon espace"
        subtitle="Créer un nouvel espace de travail"
      >
        <div class="d-flex align-center flex-column mt-3">
          <page-loader />
          <div class="text-center mt-5">
            <div class="primary--text text-h4 font-weight-thin font-alt">Votre espace est en cours de création.</div>
            <div class="op-60 primary--text font-alt text-subtitle-1">Vous allez être redirigé...</div>
          </div>
        </div>
      </space-form-step>
    </core-stepper>
  </v-container>
</template>

<script>
  import PageLoader from '@/modules/core/layout/PageLoader'
  import {empty} from '@/utils/variables'
  import SpaceFormName from '@/modules/spaces/components/Form/FormStep/SpaceFormName'
  import SpaceFormEstablishmentSelect from '@/modules/spaces/components/Form/FormStep/SpaceFormEstablishmentSelect'
  import {create as createSpace} from '@/modules/spaces/api'
  import {create as createEstablishment} from '@/modules/establishments/api'
  import CoreStepper from '@/modules/core/components/CoreStepper'
  import SpaceFormStep from '@/modules/spaces/components/Form/FormStep/SpaceFormStep'
  import SpaceFormEstablishment from '@/modules/spaces/components/Form/FormStep/SpaceFormEstablishment'
  import SpaceFormEstablishmentCreate from '@/modules/spaces/components/Form/FormStep/SpaceFormEstablishmentCreate'
  import {redirect} from '@/services/space'

  export default {
    name: 'SpaceFormStepper',
    components: {
      PageLoader,
      SpaceFormEstablishmentCreate,
      SpaceFormEstablishment,
      SpaceFormStep,
      CoreStepper,
      SpaceFormEstablishmentSelect,
      SpaceFormName
    },
    data() {
      return {
        step: 'name',
        loading: false,
        steps: {
          space: {valid: false},
          link: {valid: false},
          establishment: {valid: false},
          establishmentForm: {valid: false}
        },
        form: {
          space: {
            name: '',
            slug: '',
            custom_host: null,
            establishment: null // Etablissement uid
          },
          establishment: null // Etablissement data
        }
      }
    },
    methods: {
      async submit() {
        this.previousStep = this.step
        this.step = 'submitting'

        try {
          if (!empty(this.form.establishment)) {
            this.form.space.establishment = (await createEstablishment(this.form.establishment)).data.uid
          }

          let {data} = await createSpace(this.form.space)

          redirect(data.slug)
        } catch (e) {
          this.$handleApiError(e)
          this.step = this.previousStep
        }
      }
    }
  }
</script>
