<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->
<template>
  <div>
    <v-card-title class="px-0 text-md-h4 text-h6 primary--text">Créer un établissement</v-card-title>

    <v-card-subtitle class="px-0 op-60 text-md-body-2 text-caption">
      Je souhaite créer un établissement pour le relier à mon espace.
    </v-card-subtitle>

    <establishment-form v-model="localValue" />

    <div class="d-flex align-center mt-5 justify-end">
      <v-btn :color="$vuetify.theme.dark ? '' :'grey lighten-3'" class="mr-2" depressed @click="$emit('prev')">
        <v-icon left dark>$prev</v-icon>
        Précédent
      </v-btn>
      <v-btn color="secondary" depressed :disabled="!value" @click="$emit('next')">
        Suivant
        <v-icon right dark>$next</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import EstablishmentForm from '@/modules/establishments/components/EstablishmentForm'

  export default {
    name: 'SpaceFormEstablishmentCreate',
    components: {EstablishmentForm},
    props: ['value'],
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    }
  }
</script>


