<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->
<template>
  <div>
    <v-card-title class="px-0 text-md-h4 text-h6 primary--text word-bw ">Relier mon espace à un établissement</v-card-title>
    <v-card-subtitle class="px-0 op-60 text-md-body-2 text-caption ">
      Vous pouvez relier un espace à un établissement existant ou en créer un nouveau
    </v-card-subtitle>

    <v-row>
      <v-col cols="6">
        <v-card class="text-center elevation-x link-establishments" @click="$emit('next')">
          <font-awesome-icon :icon="['fal', 'check']" class="success--text pt-4" size="4x" />

          <v-card-text class="text-body-2">
            Je veux relier mon espace à un établissement
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="text-center elevation-x link-establishments" @click="skip">
          <font-awesome-icon :icon="['fal', 'times']" class="error--text pt-4" size="4x" />

          <v-card-text class="text-body-2">
            Je ne souhaite pas relier mon espace à un établissement
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="d-flex align-center mt-5 justify-end">
      <v-btn depressed :color="$vuetify.theme.dark ? '' :'grey lighten-3'" @click="$emit('prev')">
        <v-icon left dark>$prev</v-icon>
        Précédent
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SpaceFormEstablishment',
    methods: {
      skip() {
        this.$emit('input', null)
        this.$emit('skip')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .link-establishments {
    min-height: 132px;
  }
</style>
