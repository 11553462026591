<template>
  <v-fade-transition>
    <v-card class="w-100 d-flex flex-column justify-center align-center" height="150px" elevation="0" :class="$vuetify.theme.dark ? 'transparent' :'primary'">
      <div class="d-flex pa-3 mb-5">
        <v-img src="@/assets/img/applogo/mspace-logo-light.png" class="ma-auto" max-width="250px" contain />
        <v-spacer />
        <slot name="prepend-right" />
      </div>

      <div class="text-h5 white--text text-center font-alt">{{ title }}</div>
      <div class="text-subtitle-2 font-weight-light white--text text-center font-alt">
        {{ subtitle }}
      </div>
    </v-card>
  </v-fade-transition>
</template>

<script>
  export default {
    name: 'SpaceFormIllustrationMobile',
    props: {
      title: {
        type: String,
        default: 'Titre',
        required: true
      },
      subtitle: {
        type: String,
        default: 'Sous titre',
        required: true
      }
    }
  }
</script>
