<template>
  <v-text-field ref="input" v-model="localValue" v-bind="$attrs" :rules="rules" />
</template>

<script>
  // Représente un text field dont la valeur exposé respecte toujours les rules définies

  export default {
    name: 'CoreValidTextField',
    props: {
      value: String,
      rules: {
        type: Array,
        required: true
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', this.$refs.input.validate() ? value : null)
        }
      }
    }
  }
</script>
