*<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-form ref="form" lazy-validation>
    <v-text-field v-model="form.name" :rules="[rules.required]" label="Nom de l'établissement" solo flat />
    <v-text-field v-model="form.address.address1" :rules="[rules.required]" label="Adresse" solo flat />
    <v-text-field
      v-model="form.address.zip_code"
      :rules="[rules.required]"
      label="Code postal"
      solo flat
    />
    <v-select
      v-model="form.address.city" :rules="[rules.required]"
      :disabled="cityField.isDisabled || cityField.isLoading"
      item-text="name"
      item-value="insee_code"
      solo flat
      :loading="cityField.isLoading"
      :items="cities"
      label="Ville"
    />
  </v-form>
</template>

<script>
  import {debounce} from 'throttle-debounce'
  import {rules} from '@/services/rules'
  import {empty} from '@/utils/variables'
  import {deepCopy} from '@/utils/object'
  import * as ZipCodes from '@/modules/address/api/zip-codes'

  export default {
    name: 'EstablishmentForm',
    props: ['value'],
    data() {
      return {
        rules,
        form: this.value ? deepCopy(this.value) : {
          name: '',
          address: {
            address1: '',
            zip_code: '',
            city: ''
          }
        },
        cityField: {
          isLoading: false,
          isDisabled: true
        },
        cities: []
      }
    },

    computed: {
      valid() {
        return !empty(this.form.name) &&
          !empty(this.form.address.address1) &&
          !empty(this.form.address.zip_code) &&
          !empty(this.form.address.city)
      }
    },

    watch: {
      ['form.address.zip_code']: debounce(800, function () {
        this.fetchCities()
      }),

      valid() {
        this.valid ? this.$emit('input', this.form) : this.$emit('input', '')
      }
    },

    created() {
      if (!empty(this.form.address.city) && 'object' === typeof this.form.address.city) {
        let {name, insee_code} = this.form.address.city

        this.cities = [{name, insee_code}]

        this.form.address.city = this.form.address.city.insee_code
      }
    },

    methods: {
      async fetchCities() {
        if (empty(this.form.address.zip_code)) {
          this.cityField = {
            isLoading: false,
            isDisabled: true
          }
          this.cities = []

          return
        }

        this.cityField.isLoading = true
        try {
          let {data} = await ZipCodes.get(this.form.address.zip_code)
          this.cities = data.cities
          if (1 === this.cities.length) {
            this.form.address.city = this.cities[0].insee_code
            return
          }

          if (!empty(this.form.address.city.insee_code)) {
            let insee = this.cities.find(v => v.insee_code === this.form.address.city.insee_code) || null

            if (insee) {
              this.form.address.city = insee
            }
          }
        } catch (e) {

          this.$handleApiError(e)
        } finally {
          this.cityField.isLoading = false
          if (!empty(this.cities)) {
            this.cityField.isDisabled = false
          }
        }
      }
    }
  }
</script>
