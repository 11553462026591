<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->
<template>
  <v-fade-transition>
    <div>
      <div class="selector-animation">
        <div />
        <div />
        <div />
        <div />
      </div>
      <img alt="Bienvenue sur Medunion urgences" src="@/assets/img/applogo/mspace-logo-light.png"
           class="mx-auto mb-11 medical-space-logo"
      >
      <div class="display-2 white--text mb-2 text-center font-alt">{{ title }}</div>
      <div class="title font-weight-light white--text text-center font-alt">
        {{ subtitle }}
      </div>
    </div>
  </v-fade-transition>
</template>

<script>

  export default {
    name: 'SpaceFormIllustration',
    props: {
      title: {
        type: String,
        default: 'Titre',
        required: true
      },
      subtitle: {
        type: String,
        default: 'Sous titre',
        required: true
      }
    }
  }
</script>
