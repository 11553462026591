<script>
  export default {
    name: 'CoreStepper',
    props: {
      value: String
    },
    data() {
      return {
      }
    },
    render(h) {
      let slot = this.$slots.default.find(item => item.componentOptions.propsData.step === this.value)
      return slot ? h('div', {
        on: {
          step(value) {
            this.step = value
          }
        }
      }, [slot]) : null
    }
  }
</script>
